@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f9fbfd;
    font-size: 14px;
}

a{
    text-decoration: none;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* Images */
.img-250 {
    height: 150px;
}

.img-250 svg {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
}

.app-logo {
    display: block;
    width: 50px;
    height: auto;
}

.app-logo svg {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
}

.navbar a {
    color: inherit;
    text-decoration: none;
    font-size: 14px;
}

.navbar a:hover {
    color: inherit;
}

.navbar .dropdown-menu {
    padding: 0;
    transform: translateY(1px);
    overflow: hidden;
}

.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .show > .nav-link {
    color: #000;
    background: #fff;
    border-radius: 3px;
}

.navbar .navbar-nav .nav-link {
    color: #fff;
    font-size: 14px;
}

.navbar .navbar-nav .nav-link a {
    text-decoration: none;
    color: #fff;
}

.breadcrumb a {
    color: #000;
    text-decoration: none;
}

.breadcrumb {
    margin: 0;
}

.breadcrumb-item {
    font-size: 14px;
}

.breadcrumb-item.active {
    font-weight: 500;
    color: #000;
}

.card {
    border-color: #ddd;
    overflow: hidden;
}

.btn {
    border: none;
}

.btn-primary {
    background-color: #136091;
}

.app-bg-blue {
    background-color: #136091;
}

.navbar-toggler-icon {
    fill: #fff;
    color: #fff;
    /* background-color: #000; */
}

@media (max-width: 1023px) {
    .navbar-collapse {
        margin-top: 15px;
    }

    .navbar-nav .nav-link {
        padding-left: 15px;
    }

    .dropdown-toggle.nav-link {
        padding-left: 15px;
    }

    .navbar .dropdown-menu {
        position: relative;
    }
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}


.dropdown-item a {
    display: block;
    padding: .25rem 1rem;
}
